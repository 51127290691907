import { all, call, put, select, takeEvery, delay } from 'redux-saga/effects';
import { translate } from 'react-i18nify';
import Api from './Api';
import * as AppTypes from '../actions/app';
import * as BasicFilterTypes from '../actions/basic_filtering';
import * as Types from '../actions/advanced_filtering';
import * as AccountTypes from '../actions/account';
import { getAccountId } from '../selectors/account';

const update = require('immutability-helper');

const defaultConfig = {
  ruleModal: {
    adGroup: [],
    selectedTypeIndex: 0,
    selectedActionIndex: 0,
    domains: [],
    urls: [],
    origDomains: '',
    origUrls: '',
    origCategories: '',
    categories: [],
    inputIndex: 0,
  },
  safesearchModal: {
    adGroup: [],
    show: false,
    enabled: false,
    disabledAD: false,
  },
  urlLoggingModal: {
    adGroup: [],
    show: false,
    enabled: false,
    disabledAD: false,
  },
};

const defaultTimeConstraint = {
  startError: false,
  show: false,
  loading: false,
  disabled: false,
  modifierId: '',
  id: '',
  ruleset_id: '',
  selectIndex: 0,
  startTime: '12:00',
  endTime: '13:00',
  dow: {
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  },
};

const defaultSearch = {
  input: '',
  callback: null,
  query: '',
  queryField: 0,
  match: {
    query: '',
    type: '',
  },
};

export default (
  state = {
    safesearchModal: {
      ...defaultConfig.safesearchModal,
      fields: {
        enabled: false,
      },
      changes: {},
    },
    urlLoggingModal: {
      ...defaultConfig.urlLoggingModal,
      fields: {
        enabled: false,
      },
      changes: {},
    },
    mainButton: {
      selectIndex: 0,
    },
    ruleModal: {
      ...defaultConfig.ruleModal,
    },
    everyoneRuleModal: {
      show: false,
      loading: false,
    },
    addRuleModal: {
      show: false,
      loading: false,
      disabled: false,
    },
    editTimeConstraintModal: {
      ...defaultTimeConstraint,
      dow: {
        ...defaultTimeConstraint.dow,
      },
    },
    modifiers: {},
    selectedRule: {
      loading: false,
      show: false,
      id: '',
      ruleset_id: '',
      error: '',
    },
    search: {
      ...defaultSearch,
      queryFields: [
        {
          label: translate('components.wfLogs.userId'),
          field: 'users',
        },
        {
          label: translate('components.advancedFiltering.groups'),
          field: 'groups',
        },
        {
          label: translate('components.wfLogs.categories'),
          field: 'categories',
        },
        {
          label: translate('components.advancedFiltering.domains'),
          field: 'domains',
          type: 'input',
        },
      ],
    },
    highlightedRule: '',
    userToRuleset: {},
    processing: false,
    processingRule: false,
    groupToRuleset: {},
    ruleset: {
      rules: [],
    },
    policy_settings: {
      default_action: 'allow',
    },
  },
  action
) => {
  let rulesetRules;
  let newIndex;
  let index;
  let order;

  switch (action.type) {
    case Types.AF_TOGGLE_URL_LOGGING:
      return {
        ...state,
        urlLoggingModal: {
          ...state.urlLoggingModal,
          enabled: !state.urlLoggingModal.enabled,
        },
      };
    case Types.AF_CLOSE_URL_LOGGING:
      return {
        ...state,
        urlLoggingModal: {
          ...state.urlLoggingModal,
          show: false,
          enabled: false,
          disabledAD: false,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: [],
        },
      };
    case Types.AF_OPEN_URL_LOGGING:
      return {
        ...state,
        urlLoggingModal: {
          ...state.urlLoggingModal,
          enabled: action.enabled || state.urlLoggingModal.enabled,
          disabledAD: action.disabledAD,
          show: true,
          fields: {
            ...state.urlLoggingModal,
            enabled: action.enabled || state.urlLoggingModal.enabled,
          },
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: action.selectedAD || [],
        },
      };
    case Types.AF_TOGGLE_SAFESEARCH:
      return {
        ...state,
        safesearchModal: {
          ...state.safesearchModal,
          enabled: !state.safesearchModal.enabled,
        },
      };
    case Types.AF_CLOSE_SAFESEARCH:
      return {
        ...state,
        safesearchModal: {
          ...state.safesearchModal,
          show: false,
          enabled: false,
          disabledAD: false,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: [],
        },
      };
    case Types.AF_OPEN_SAFE_SEARCH:
      return {
        ...state,
        safesearchModal: {
          ...state.safesearchModal,
          enabled: action.enabled || state.safesearchModal.enabled,
          disabledAD: action.disabledAD,
          show: true,
          fields: {
            ...state.safesearchModal,
            enabled: action.enabled || state.safesearchModal.enabled,
          },
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: action.selectedAD || [],
        },
      };
    case Types.AF_BUTTON_MENU_SELECT:
      return {
        ...state,
        mainButton: {
          ...state.mainButton,
          selectIndex: action.index,
        },
      };
    case Types.AF_EDIT_EVERYONE_RULE:
      return {
        ...state,
        everyoneRuleModal: {
          show: false,
          loading: true,
        },
      };
    case Types.SELECT_AF_EVERYONE_ACTION:
      return {
        ...state,
        policy_settings: {
          ...state.policy_settings,
          default_action: action.index === 1 ? 'allow' : 'deny',
        },
      };
    case Types.AF_APPLY_SEARCH_FILTER:
      if (!state.search.query) {
        return {
          ...state,
          search: {
            ...state.search,
            match: {
              ...state.search.match,
              query: '',
              type: '',
            },
          },
        };
      }

      return {
        ...state,
        processing: true,
        search: {
          ...state.search,
          match: {
            ...state.search.match,
            query: state.search.query,
            type: state.search.queryFields[state.search.queryField]['field'],
          },
        },
      };
    case Types.AF_GET_RULESETS:
      return {
        ...state,
        processing: true,
      };
    case Types.SUBMIT_RULES_REORDER:
    case Types.SUBMIT_RULESETS_REORDER:
      return {
        ...state,
        processingRule: true,
      };
    case Types.HIGHLIGHT_RULE:
      return {
        ...state,
        highlightedRule: action.rule,
      };
    case Types.SELECT_AF_INPUT_INDEX:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          inputIndex: action.index,
        },
      };
    case Types.SELECT_AF_TYPE:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          selectedTypeIndex: action.index,
        },
      };
    case Types.AF_GET_MODIFIER_SUCCESS:
      console.log('the action: ', action);
      return {
        ...state,
        modifiers: action.modifiers,
      };
    case Types.AF_TIME_EDIT_FAILED:
    case Types.AF_TIME_EDIT_SUCCESS:
      return {
        ...state,
        processing: false,
      };
    case Types.AF_START_TIME_ERROR:
      return {
        ...state,
        editTimeConstraintModal: {
          ...state.editTimeConstraintModal,
          startError: true,
          show: true,
        },
        processing: false,
      };
    case Types.AF_SELECT_TIME_RANGE:
      return {
        ...state,
        editTimeConstraintModal: {
          ...state.editTimeConstraintModal,
          [action.name]: action.value,
        },
      };
    case Types.AF_SAVE_TIME_CONSTRAINT:
      return {
        ...state,
        processing: true,
        editTimeConstraintModal: {
          ...state.editTimeConstraintModal,
          show: false,
        },
      };
    case Types.AF_SELECT_DOW:
      return {
        ...state,
        editTimeConstraintModal: {
          ...state.editTimeConstraintModal,
          dow: {
            ...state.editTimeConstraintModal.dow,
            [action.name]: action.value,
          },
        },
      };
    case Types.SELECT_AF_TIME_ACTION:
      return {
        ...state,
        editTimeConstraintModal: {
          ...state.editTimeConstraintModal,
          selectIndex: action.index,
        },
      };
    case Types.SELECT_AF_ACTION:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          selectedActionIndex: action.index,
        },
      };
    case Types.AF_CHANGE_DOMAIN:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          domains: action.value,
          inputIndex: action.value.length,
        },
        selectedRule: {
          ...state.selectedRule,
          error: '',
        },
      };
    case Types.AF_CHANGE_URL:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          urls: action.value,
          inputIndex: action.value.length,
        },
        selectedRule: {
          ...state.selectedRule,
          error: '',
        },
      };
    case Types.AF_CHANGE_AD:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.value || [],
        },
      };
    case Types.GET_POLICY_SETTINNGS_SUCCESS:
      return {
        ...state,
        policy_settings: {
          ...state.policy_settings,
          default_action: action.data.default_action,
        },
      };
    case Types.GET_RULESET_SUCCESS:
      return {
        ...state,
        userToRuleset: action.userToRuleset,
        groupToRuleset: action.groupToRuleset,
        ruleset: action.ruleset,
        processing: false,
        safesearchModal: {
          ...state.safesearchModal,
          show: false,
          enabled: false,
          disabledAD: false,
        },
        urlLoggingModal: {
          ...state.urlLoggingModal,
          show: false,
          enabled: false,
          disabledAD: false,
        },
      };
    case Types.AF_CLOSE_EDIT_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: '',
          show: false,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
        },
      };
    case Types.AF_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: false,
        },
      };
    case Types.AF_OPEN_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: true,
          disabled: action.disabled,
        },
        ruleModal: {
          ...defaultConfig.ruleModal,
          adGroup: action.selectedAD || [],
        },
      };
    case Types.AF_CLOSE_ADD_RULE:
      return {
        ...state,
        addRuleModal: {
          ...state.addRuleModal,
          show: false,
        },
        selectedRule: {
          ...state.selectedRule,
          error: '',
          show: false,
        },
      };
    case Types.AF_OPEN_EVERYONE_EDIT_RULE:
      return {
        ...state,
        everyoneRuleModal: {
          ...state.everyoneRuleModal,
          show: true,
        },
      };
    case Types.AF_CLOSE_EVERYONE_RULE:
      return {
        ...state,
        everyoneRuleModal: {
          ...state.everyoneRuleModal,
          show: false,
        },
      };
    case Types.AF_OPEN_CLOSE_TIME_CONSTRAINT:
      return {
        ...state,
        editTimeConstraintModal: {
          ...defaultTimeConstraint,
          dow: {
            ...defaultTimeConstraint.dow,
          },
        },
      };
    case Types.AF_OPEN_EDIT_RULESET:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          show: true,
          id: action.rule.id,
          display: 'ruleset',
          ruleset_id: action.rule.ruleset_id,
        },
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.selectedAD,
        },
      };
    case Types.AF_OPEN_EDIT_TIME_CONSTRAINT:
      let mod = {
        ...state.editTimeConstraintModal,
        startError: false,
        show: true,
        id: action.rule.id,
        ruleset_id: action.rule.ruleset_id,
      };
      if (action.rule.id in state.modifiers) {
        let m = state.modifiers[action.rule.id];
        let start = m['policy']['minutes'][0]['start'];
        let r = start % 60;
        let h = Math.floor(start / 60);
        let startTime = String(h) + ':';
        if (h < 10) {
          startTime = '0' + String(h) + ':';
        }
        if (r < 10) {
          startTime += '0' + String(r);
        } else {
          startTime += String(r);
        }
        let end = m['policy']['minutes'][0]['end'];
        r = end % 60;
        h = Math.floor(end / 60);
        let endTime = String(h) + ':';
        if (h < 10) {
          endTime = '0' + String(h) + ':';
        }
        if (r < 10) {
          endTime += '0' + String(r);
        } else {
          endTime += String(r);
        }

        mod['modifierId'] = m['id'];
        mod['startTime'] = startTime;
        mod['endTime'] = endTime;
        mod['selectIndex'] = 1;
        mod['dow'] = {
          Sunday: m['policy']['days'][0],
          Monday: m['policy']['days'][1],
          Tuesday: m['policy']['days'][2],
          Wednesday: m['policy']['days'][3],
          Thursday: m['policy']['days'][4],
          Friday: m['policy']['days'][5],
          Saturday: m['policy']['days'][6],
        };
      }
      return {
        ...state,
        editTimeConstraintModal: {
          ...mod,
        },
      };

    case Types.AF_OPEN_EDIT_RULE:
      let typeIndex = 0;
      let selectIndex = 0;
      let domains = [];
      let urls = [];
      let categories = [];

      if (action.rule.type === 'category') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'categories');
        categories = [...action.rule.categories];
      } else if (action.rule.type === 'domain') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'domains');
        selectIndex = action.rule.domains.length;
        domains = [...action.rule.domains];
      } else if (action.rule.type === 'url') {
        typeIndex = Types.TYPE_OPTIONS.findIndex(x => x.key === 'url');
        selectIndex = action.rule.urls.length;
        urls = [...action.rule.urls];
      }

      let data = {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          show: true,
          id: action.rule.id,
          display: undefined,
          ruleset_id: action.rule.ruleset_id,
          error: '',
        },
        ruleModal: {
          ...state.ruleModal,
          adGroup: action.selectedAD,
          selectedTypeIndex: typeIndex,
          selectedActionIndex: Types.ACTION_OPTIONS.findIndex(
            x => x.key === action.rule.action
          ),
          origDomains: domains.toString(),
          origUrls: urls.toString(),
          origCategories: categories.toString(),
          domains: domains,
          urls: urls,
          categories: categories,
          inputIndex: selectIndex,
        },
      };

      return data;
    case Types.AF_EDIT_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: '',
          show: false,
        },
      };
    case Types.AF_EDIT_RULE_ERROR:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: action.error,
          show: true,
        },
      };
    case Types.AF_CREATE_RULE_ERROR:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          error: action.error,
          show: false,
        },
        addRuleModal: {
          ...state.addRuleModal,
          show: true,
        },
      };
    case Types.AF_DELETE_RULESET_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          id: action.rule_id,
        },
      };
    case Types.AF_DELETE_RULE:
      return {
        ...state,
        selectedRule: {
          ...state.selectedRule,
          id: action.id,
          ruleset_id: action.ruleset_id,
        },
      };
    case Types.AF_CLEAR_FILTER_SEARCH_INPUT:
      return {
        ...state,
        search: {
          ...state.search,
          input: '',
          callback: null,
        },
      };
    case Types.AF_FILTER_AD_SEARCH_INPUT:
    case Types.AF_FILTER_SEARCH_INPUT:
      return {
        ...state,
        search: {
          ...state.search,
          input: action.value,
          callback: action.callback,
        },
      };
    case Types.AF_SEARCH_INPUT_ONCHANGE:
      return {
        ...state,
        search: {
          ...state.search,
          query: action.value,
        },
      };
    case Types.AF_SEARCH_FIELD_ONCHANGE:
      return {
        ...state,
        search: {
          ...state.search,
          query: '',
          queryField: action.field,
        },
      };
    case Types.AF_RESET_SEARCH:
      return {
        ...state,
        search: {
          ...state.search, // Keep the options
          ...defaultSearch,
        },
      };
    case Types.AF_UPDATE_SELECTED_CATEGORIES:
      return {
        ...state,
        ruleModal: {
          ...state.ruleModal,
          categories: [
            ...state.ruleModal.categories.filter(
              cat => action.removed.indexOf(cat) === -1
            ),
            ...action.added,
          ],
        },
      };
    case Types.REORDER_RULES:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $splice: [
                    [action.dragIndex, 1],
                    [
                      action.hoverIndex,
                      0,
                      state.ruleset.rules[action.parentIndex].ruleset_jump
                        .rules[action.dragIndex],
                    ],
                  ],
                },
              },
            },
          },
        }),
      };
    case Types.UPDATE_RULE_ORDER:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  [action.index]: {
                    $apply: rule => ({ ...rule, index: action.order }),
                  },
                },
              },
            },
          },
        }),
      };
    case Types.SUBMIT_RULE_ORDER:
      index = parseInt(action.originalIndex, 10);
      rulesetRules = state.ruleset.rules[action.parentIndex].ruleset_jump.rules;
      order = Object.prototype.hasOwnProperty.call(action, 'index')
        ? parseInt(action.index, 10)
        : parseInt(rulesetRules[index].index, 10);

      newIndex = order >= rulesetRules.length ? rulesetRules.length - 1 : order;

      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $apply: newRules => {
                    let newArr = newRules.slice();
                    newArr.splice(newIndex, 0, ...newArr.splice(index, 1));
                    return newArr.map((n, i) => ({ ...n, index: i }));
                  },
                },
              },
            },
          },
        }),
      };
    case Types.REORDER_RULESETS:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            $splice: [
              [action.dragIndex, 1],
              [action.hoverIndex, 0, state.ruleset.rules[action.dragIndex]],
            ],
          },
        }),
      };
    case Types.SUBMIT_RULESET_ORDER:
      return {
        ...state,
        ruleset: update(state.ruleset, {
          rules: {
            $splice: [
              [action.originalIndex, 1],
              [action.index, 0, state.ruleset.rules[action.originalIndex]],
            ],
          },
        }),
      };
    case Types.SUBMIT_RULES_REORDER_SUCCESS:
      return {
        ...state,
        processingRule: false,
        ruleset: update(state.ruleset, {
          rules: {
            [action.parentIndex]: {
              ruleset_jump: {
                rules: {
                  $apply: rules => rules.map((n, i) => ({ ...n, index: i })),
                },
              },
            },
          },
        }),
      };
    case Types.SUBMIT_RULE_ORDER_SUCCESS:
    case Types.SUBMIT_RULESETS_REORDER_SUCCESS:
      return {
        ...state,
        processingRule: false,
      };
    case Types.SUBMIT_RULE_ORDER_FAILURE:
    case Types.SUBMIT_RULESETS_REORDER_FAILURE:
    case Types.SUBMIT_RULES_REORDER_FAILURE:
      return {
        ...state,
        processingRule: false,
      };
    default:
      return state;
  }
};

function* addUrlLogging() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const ruleset = store.advanced_filtering.ruleset;
    const rules = ruleset.rules;
    const userToRuleset = store.advanced_filtering.userToRuleset;
    const groupToRuleset = store.advanced_filtering.groupToRuleset;
    const adGroup = ruleData.adGroup;
    const adMap = store.account.adMap;
    const urlLogging = store.advanced_filtering.urlLoggingModal.enabled;
    const selectedType = Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];

    let errors = [];
    for (let i = 0; i < adGroup.length; i++) {
      try {
        let rulesetId;
        if (
          adGroup[i]['value'] !== 'everyone' &&
          !userToRuleset[adGroup[i]['value']] &&
          !groupToRuleset[adGroup[i]['value']]
        ) {
          // Create ruleset for user or group
          let jumpsetBody = {
            account_id: store.account.selected,
            ruleset_id: ruleset.id,
            index: 0,
            action: 'jump',
            type: Types.RULE_TYPES['jump'],
            url_logging: urlLogging,
          };

          if (adGroup[i]['type'] === 'user') {
            jumpsetBody['user_ids'] = [adGroup[i]['value']];
          } else {
            jumpsetBody['group_ids'] = [adGroup[i]['value']];
          }

          const result = yield call(Api.ruleSets.create, {
            account_id: store.account.selected,
          });

          jumpsetBody['ruleset_jump'] = result['id'];

          yield call(Api.rules.create, jumpsetBody);
          rulesetId = result['id'];
        } else if (adGroup[i]['value'] === 'everyone') {
          let ruleId = null;
          if (!groupToRuleset['everyone']) {
            // Create ruleset for user or group
            let jumpsetBody = {
              account_id: store.account.selected,
              ruleset_id: ruleset.id,
              index: 9000, // index greater than ruleset to append to end. Policy Service will fix count
              action: 'jump',
              type: Types.RULE_TYPES['jump'],
              rule_name: 'everyone',
              url_logging: urlLogging,
            };

            const result = yield call(Api.ruleSets.create, {
              account_id: store.account.selected,
            });

            jumpsetBody['ruleset_jump'] = result['id'];

            yield call(Api.rules.create, jumpsetBody);
            ruleId = result['id'];
            rulesetId = result['ruleset_id'];
          } else {
            for (let y = 0; y < rules.length; y++) {
              if (
                rules[y]['user_ids'].length === 0 ||
                rules[y]['group_ids'].length === 0
              ) {
                ruleId = rules[y]['id'];
                rulesetId = rules[y]['ruleset_id'];
              }
            }
          }
          // TODO: Update everyone rule safe search
          console.log('the everyone ruleset id: ', rulesetId);
          yield call(
            Api.rules.update,
            {
              account_id: store.account.selected,
              ruleset_id: rulesetId,
              rule_id: ruleId,
            },
            {
              url_logging: urlLogging,
            }
          );
        } else {
          let ruleId = null;
          for (let y = 0; y < rules.length; y++) {
            if (
              rules[y]['user_ids'].includes(adGroup[i]['value']) ||
              rules[y]['group_ids'].includes(adGroup[i]['value'])
            ) {
              ruleId = rules[y]['id'];
              rulesetId = rules[y]['ruleset_id'];
            }
          }

          yield call(
            Api.rules.update,
            {
              account_id: store.account.selected,
              ruleset_id: rulesetId,
              rule_id: ruleId,
            },
            {
              url_logging: urlLogging,
            }
          );
        }
      } catch (error) {
        console.log('error: ', error);
        errors.push(adGroup[i]['label']);
      }
    }

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    // Display error or success message if any
    if (errors.length > 0) {
      yield put(
        AppTypes.error(
          translate('components.advancedFiltering.createErrors', {
            resources: errors.join(', '),
          })
        )
      );
    } else {
      yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
    }
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* addSafesearch() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const ruleset = store.advanced_filtering.ruleset;
    const rules = ruleset.rules;
    const userToRuleset = store.advanced_filtering.userToRuleset;
    const groupToRuleset = store.advanced_filtering.groupToRuleset;
    const adGroup = ruleData.adGroup;
    const adMap = store.account.adMap;
    const safesearch = store.advanced_filtering.safesearchModal.enabled;
    const selectedType = Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];

    let errors = [];
    for (let i = 0; i < adGroup.length; i++) {
      try {
        let rulesetId;
        if (
          adGroup[i]['value'] !== 'everyone' &&
          !userToRuleset[adGroup[i]['value']] &&
          !groupToRuleset[adGroup[i]['value']]
        ) {
          // Create ruleset for user or group
          let jumpsetBody = {
            account_id: store.account.selected,
            ruleset_id: ruleset.id,
            index: 0,
            action: 'jump',
            type: Types.RULE_TYPES['jump'],
            safesearch: safesearch,
          };

          if (adGroup[i]['type'] === 'user') {
            jumpsetBody['user_ids'] = [adGroup[i]['value']];
          } else {
            jumpsetBody['group_ids'] = [adGroup[i]['value']];
          }

          const result = yield call(Api.ruleSets.create, {
            account_id: store.account.selected,
          });

          jumpsetBody['ruleset_jump'] = result['id'];

          yield call(Api.rules.create, jumpsetBody);
          rulesetId = result['id'];
        } else if (adGroup[i]['value'] === 'everyone') {
          let ruleId = null;
          if (!groupToRuleset['everyone']) {
            // Create ruleset for user or group
            let jumpsetBody = {
              account_id: store.account.selected,
              ruleset_id: ruleset.id,
              index: 9000, // index greater than ruleset to append to end. Policy Service will fix count
              action: 'jump',
              type: Types.RULE_TYPES['jump'],
              rule_name: 'everyone',
              safesearch: safesearch,
            };

            const result = yield call(Api.ruleSets.create, {
              account_id: store.account.selected,
            });

            jumpsetBody['ruleset_jump'] = result['id'];

            yield call(Api.rules.create, jumpsetBody);
            ruleId = result['id'];
            rulesetId = result['ruleset_id'];
          } else {
            for (let y = 0; y < rules.length; y++) {
              if (
                rules[y]['user_ids'].length === 0 ||
                rules[y]['group_ids'].length === 0
              ) {
                ruleId = rules[y]['id'];
                rulesetId = rules[y]['ruleset_id'];
              }
            }
          }
          // TODO: Update everyone rule safe search
          console.log('the everyone ruleset id: ', rulesetId);
          yield call(
            Api.rules.update,
            {
              account_id: store.account.selected,
              ruleset_id: rulesetId,
              rule_id: ruleId,
            },
            {
              safesearch,
            }
          );
        } else {
          let ruleId = null;
          for (let y = 0; y < rules.length; y++) {
            if (
              rules[y]['user_ids'].includes(adGroup[i]['value']) ||
              rules[y]['group_ids'].includes(adGroup[i]['value'])
            ) {
              ruleId = rules[y]['id'];
              rulesetId = rules[y]['ruleset_id'];
            }
          }

          yield call(
            Api.rules.update,
            {
              account_id: store.account.selected,
              ruleset_id: rulesetId,
              rule_id: ruleId,
            },
            {
              safesearch,
            }
          );
        }
      } catch (error) {
        console.log('error: ', error);
        errors.push(adGroup[i]['label']);
      }
    }

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    // Display error or success message if any
    if (errors.length > 0) {
      yield put(
        AppTypes.error(
          translate('components.advancedFiltering.createErrors', {
            resources: errors.join(', '),
          })
        )
      );
    } else {
      yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
    }
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* addRule() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const ruleset = store.advanced_filtering.ruleset;
    const userToRuleset = store.advanced_filtering.userToRuleset;
    const groupToRuleset = store.advanced_filtering.groupToRuleset;
    const adGroup = ruleData.adGroup;
    const adMap = store.account.adMap;
    const selectedType = Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];

    let validateDomains = {};
    let validateUrls = {};

    if (selectedType === 'domains') {
      validateDomains['domains'] = ruleData.domains;
      for (let i = 0; i < validateDomains['domains'].length; i++) {
        if (
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            validateDomains['domains'][i]
          ) ||
          /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(
            validateDomains['domains'][i]
          )
        ) {
          return yield put(
            Types.createError(
              translate('components.advancedFiltering.errors.invalidDomain')
            )
          );
        }
      }
    }
    if (selectedType === 'url') {
      validateUrls['urls'] = ruleData.urls;
      for (let i = 0; i < validateUrls['urls'].length; i++) {
        if (
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            validateUrls['urls'][i]
          ) ||
          /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(
            validateUrls['urls'][i]
          )
        ) {
          return yield put(
            Types.createError(
              translate('components.advancedFiltering.errors.invalidUrl')
            )
          );
        }
      }
    }

    let errors = [];
    for (let i = 0; i < adGroup.length; i++) {
      try {
        let rulesetId;

        let fetchIds = [];
        if (!(adGroup[i]['value'] in adMap)) {
          fetchIds.push(adGroup[i]['value']);
        }

        if (fetchIds) {
          let ids = yield call(Api.directory.readUsers, {
            account_id: store.account.selected,
            ids: fetchIds,
          });
          yield put(AccountTypes.mergeActiveDirectory(ids));
        }

        if (
          adGroup[i]['value'] !== 'everyone' &&
          !userToRuleset[adGroup[i]['value']] &&
          !groupToRuleset[adGroup[i]['value']]
        ) {
          // Create ruleset for user or group
          let jumpsetBody = {
            account_id: store.account.selected,
            ruleset_id: ruleset.id,
            index: 0,
            action: 'jump',
            type: Types.RULE_TYPES['jump'],
          };

          if (adGroup[i]['type'] === 'user') {
            jumpsetBody['user_ids'] = [adGroup[i]['value']];
          } else {
            jumpsetBody['group_ids'] = [adGroup[i]['value']];
          }

          const result = yield call(Api.ruleSets.create, {
            account_id: store.account.selected,
          });

          jumpsetBody['ruleset_jump'] = result['id'];

          yield call(Api.rules.create, jumpsetBody);
          rulesetId = result['id'];
        } else if (adGroup[i]['value'] === 'everyone') {
          rulesetId = groupToRuleset['everyone'];
          if (!rulesetId) {
            // Create ruleset for user or group
            let jumpsetBody = {
              account_id: store.account.selected,
              ruleset_id: ruleset.id,
              index: 9000, // index greater than ruleset to append to end. Policy Service will fix count
              action: 'jump',
              type: Types.RULE_TYPES['jump'],
              rule_name: 'everyone',
            };

            const result = yield call(Api.ruleSets.create, {
              account_id: store.account.selected,
            });

            jumpsetBody['ruleset_jump'] = result['id'];

            yield call(Api.rules.create, jumpsetBody);
            rulesetId = result['id'];
          }
        } else {
          // Get ruleset ID
          rulesetId =
            userToRuleset[adGroup[i]['value']] ||
            groupToRuleset[adGroup[i]['value']] ||
            adGroup[i]['value'];
        }

        let body = {
          account_id: store.account.selected,
          ruleset_id: rulesetId,
          index: 0,
          action: Types.ACTION_OPTIONS[ruleData.selectedActionIndex]['key'],
          type: Types.RULE_TYPES[selectedType],
        };

        if (selectedType === 'categories') {
          body['categories'] = ruleData.categories;
        } else if (selectedType === 'domains') {
          body['domains'] = ruleData.domains;
        } else if (selectedType === 'url') {
          body['urls'] = ruleData.urls;
        }
        yield call(Api.rules.create, body);
      } catch (error) {
        console.log('Error: ', error);
        errors.push(adGroup[i]['label']);
      }
    }

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    // Display error or success message if any
    if (errors.length > 0) {
      yield put(
        AppTypes.error(
          translate('components.advancedFiltering.createErrors', {
            resources: errors.join(', '),
          })
        )
      );
    } else {
      yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
    }
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* getRulesets() {
  try {
    const store = yield select();

    // Get wcs versiono can categories
    const catsResult = yield call(Api.getData, {
      page: 'get_basic_policies',
    });
    const cat = {
      categories: catsResult['categories'],
      supercategories: catsResult['supercategories'],
    };
    yield put(
      BasicFilterTypes.storeLoadedCats(
        cat['categories'],
        cat['supercategories'],
        catsResult['catsv3'],
        catsResult['wcs_version']
      )
    );

    const activeDirectory = store.account.activeDirectory;
    if (activeDirectory.length === 0) {
      const [adUsers, adGroups] = yield all([
        call(Api.directory.readUsers, {
          account_id: store.account.selected,
        }),
        call(Api.directory.readGroups, {
          account_id: store.account.selected,
        }),
      ]);
      const adResult = {
        users: adUsers['users'],
        groups: adGroups['groups'],
      };
      yield put(AccountTypes.getActiveDirectorySuccess(adResult));
    }

    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });

    const policy_settings_result = yield call(
      Api.accounts.policy_settings,
      store.account.selected
    );

    const rules = result['rules'];
    let adIds = [];

    for (let i = 0; i < rules.length; i++) {
      // TODO: Need to handle edge case of groups

      if (rules[i]['user_ids'].length > 0) {
        adIds.push(rules[i]['user_ids'][0]);
      }
    }
    const updatedStore = yield select();
    const adMap = updatedStore.account.adMap;

    let fetchIds = [];
    for (let i = 0; i < adIds.length; i++) {
      if (!(adIds[i] in adMap)) {
        fetchIds.push(adIds[i]);
      }
    }

    if (fetchIds) {
      let ids = yield call(Api.directory.readUsers, {
        account_id: store.account.selected,
        user_ids: fetchIds,
      });
      yield put(AccountTypes.mergeActiveDirectory(ids));
    }

    const policy_modifiers = yield call(
      Api.modifiers.get,
      store.account.selected
    );

    yield put(Types.getRulesetSuccess(result));
    yield put(Types.getPolicySettingsSuccess(policy_settings_result));
    yield put(Types.getModifierSuccess(policy_modifiers['modifiers']));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* getFilterRulesets() {
  try {
    const store = yield select();
    const query = store.advanced_filtering.search.match.query;
    const query_type = store.advanced_filtering.search.match.type;
    let result;

    if (query_type === 'users') {
      result = yield call(Api.ruleSets.users.read, {
        account_id: store.account.selected,
        user_id: query.user_id,
      });
    } else if (query_type === 'groups') {
      result = yield call(Api.ruleSets.groups.read, {
        account_id: store.account.selected,
        group_id: query.group_id,
      });
    } else {
      result = yield call(Api.ruleSets.read, {
        account_id: store.account.selected,
      });
    }
    yield put(Types.getRulesetSuccess(result));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* editEveryoneRule() {
  try {
    const store = yield select();
    yield call(Api.accounts.policy_settings_update, store.account.selected, {
      default_action: store.advanced_filtering.policy_settings.default_action,
    });

    // Get ruleset to update UI.
    yield call(getFilterRulesets);
  } catch (error) {
    console.log('the error: ', error);
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.message));
  }
}

function* editRule() {
  try {
    const store = yield select();
    const ruleData = store.advanced_filtering.ruleModal;
    const selectedRule = store.advanced_filtering.selectedRule;

    const selectedType =
      selectedRule.display ||
      Types.TYPE_OPTIONS[ruleData.selectedTypeIndex]['key'];
    let body = {
      action: Types.ACTION_OPTIONS[ruleData.selectedActionIndex]['key'],
    };

    if (selectedType === 'ruleset') {
      const adGroup = ruleData.adGroup;
      body = {
        group_ids: adGroup.type === 'group' ? [adGroup.value] : [],
        user_ids: adGroup.type === 'user' ? [adGroup.value] : [],
      };
    } else if (selectedType === 'categories') {
      body['categories'] = ruleData.categories;
      if (body['categories'].length === 0) {
        return yield put(
          Types.editError(
            translate('components.advancedFiltering.errors.emptyCategories')
          )
        );
      }
    } else if (selectedType === 'domains') {
      body['domains'] = ruleData.domains;
      if (body['domains'].length === 0) {
        return yield put(
          Types.editError(
            translate('components.advancedFiltering.errors.emptyDomain')
          )
        );
      }
      if (body['domains'].length > 0) {
        for (let index = 0; index < body['domains'].length; index++) {
          if (
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              body['domains'][index]
            ) ||
            /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(
              body['domains'][index]
            )
          ) {
            return yield put(
              Types.editError(
                translate('components.advancedFiltering.errors.invalidDomain')
              )
            );
          }
        }
      }
    } else if (selectedType === 'url') {
      body['urls'] = ruleData.urls;
      if (body['urls'].length === 0) {
        return yield put(
          Types.editError(
            translate('components.advancedFiltering.errors.emptyUrl')
          )
        );
      }
      if (body['urls'].length > 0) {
        for (let index = 0; index < body['urls'].length; index++) {
          if (
            /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
              body['urls'][index]
            ) ||
            /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g.test(
              body['urls'][index]
            )
          ) {
            return yield put(
              Types.editError(
                translate('components.advancedFiltering.errors.invalidUrl')
              )
            );
          }
        }
      }
    }

    yield call(
      Api.rules.update,
      {
        account_id: store.account.selected,
        ruleset_id: selectedRule.ruleset_id,
        rule_id: selectedRule.id,
      },
      body
    );

    // Get ruleset to update UI.
    yield call(getFilterRulesets);
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    console.log('the error: ', error);
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.message));
  }
}

function* deleteRule() {
  try {
    const store = yield select();
    const selectedRule = store.advanced_filtering.selectedRule;
    const rulesets = store.advanced_filtering.ruleset;

    let rulesetRuleId;
    const rules = rulesets.rules;
    for (let i = 0; i < rules.length; i++) {
      if (
        rules[i]['ruleset_jump'] &&
        rules[i]['ruleset_jump']['id'] === selectedRule.ruleset_id &&
        rules[i]['ruleset_jump']['rules'].length <= 1
      ) {
        rulesetRuleId = rules[i]['id'];
        break;
      }
    }

    yield call(Api.rules.delete, {
      account_id: store.account.selected,
      ruleset_id: selectedRule.ruleset_id,
      rule_id: selectedRule.id,
    });

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* deleteRulesetRule() {
  try {
    const store = yield select();
    const selectedRule = store.advanced_filtering.selectedRule;

    yield call(Api.ruleSets.delete, {
      account_id: store.account.selected,
      id: selectedRule.id,
    });

    // Get ruleset to update UI.
    const result = yield call(Api.ruleSets.read, {
      account_id: store.account.selected,
    });
    yield put(Types.getRulesetSuccess(result));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getRulesetFailure(error.error));
  }
}

function* reorderRules(action) {
  try {
    const accountId = yield select(getAccountId);

    yield call(
      Api.rules.update,
      {
        account_id: accountId,
        ruleset_id: action.rulesetId,
        rule_id: action.id,
      },
      {
        index: action.index,
      }
    );
    yield put(Types.submitRulesReorderSuccess(action.parentIndex));
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error('Failed'));
    yield put(
      Types.reorderRules(action.index, action.originalIndex, action.parentIndex)
    );
    yield put(Types.submitRulesReorderFailure());
  }
  yield delay(5000);
  yield put(AppTypes.clearError());
}

function* reorderRulesets(action) {
  try {
    const accountId = yield select(getAccountId);
    yield call(
      Api.rules.update,
      {
        account_id: accountId,
        ruleset_id: action.rulesetId,
        rule_id: action.id,
      },
      {
        index: action.index,
      }
    );
    yield put(Types.submitRulesetsReorderSuccess());
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    yield put(AppTypes.error('Failed'));
    yield put(Types.reorderRulesets(action.index, action.originalIndex));
    yield put(Types.submitRulesetsReorderFailure());
  }
  yield delay(5000);
  yield put(AppTypes.clearError());
}

function* filterUsers(action) {
  try {
    const store = yield select();
    const search = store.advanced_filtering.search;
    const accountId = yield select(getAccountId);

    let response = yield call(Api.directory.readUsers, {
      account_id: accountId,
      query: search.input,
    });

    // Make sure to only display 100 entries still
    response['users'] = response['users'].slice(0, 100);
    search.callback(AccountTypes.formatUsers({}, [], response));
  } catch (error) {
    console.log('Error: ', error);
  }
}

function* filterUsersGroups(action) {
  try {
    const store = yield select();
    const search = store.advanced_filtering.search;
    const accountId = yield select(getAccountId);

    let response = yield call(Api.directory.readUsers, {
      account_id: accountId,
      query: search.input,
    });

    // Make sure to only display 100 entries still
    response['users'] = response['users'].slice(0, 100);
    let users = AccountTypes.formatUsers({}, [], response);

    response = yield call(Api.directory.readGroups, {
      account_id: accountId,
      query: search.input,
    });
    response['groups'] = response['groups'].slice(0, 100);

    let groups = AccountTypes.formatGroups(response);
    let flatAD = [];
    flatAD = flatAD.concat(users);
    flatAD = flatAD.concat(groups);
    search.callback(flatAD);
  } catch (error) {
    console.log('Error: ', error);
  }
}

function* saveTimeConstraint(action) {
  try {
    const store = yield select();
    const accountId = yield select(getAccountId);

    const modifier = store.advanced_filtering.editTimeConstraintModal;
    const dow = modifier.dow;
    let response;

    if (modifier.startTime > modifier.endTime) {
      yield put(Types.startTimeError());
      return;
    }

    let start = modifier.startTime;
    let t = start.split(':');
    start = Number(t[0]) * 60 + Number(t[1]);
    let end = modifier.endTime;
    t = end.split(':');
    end = Number(t[0]) * 60 + Number(t[1]);

    if (modifier.selectIndex == 0) {
      if (modifier.modifierId) {
        yield call(Api.modifiers.remove, accountId, modifier.modifierId);
      } else {
        return;
      }
    }

    if (modifier.modifierId) {
      response = yield call(
        Api.modifiers.update,
        accountId,
        modifier.modifierId,
        {
          modifier_id: modifier.modifierId,
          account_id: accountId,
          rule_id: modifier.id,
          start: start,
          end: end,
          dow: [
            dow['Sunday'],
            dow['Monday'],
            dow['Tuesday'],
            dow['Wednesday'],
            dow['Thursday'],
            dow['Friday'],
            dow['Saturday'],
          ],
        }
      );
    } else {
      response = yield call(Api.modifiers.create, accountId, {
        account_id: accountId,
        rule_id: modifier.id,
        start: start,
        end: end,
        dow: [
          dow['Sunday'],
          dow['Monday'],
          dow['Tuesday'],
          dow['Wednesday'],
          dow['Thursday'],
          dow['Friday'],
          dow['Saturday'],
        ],
      });
    }

    if ('error' in response) {
      yield put(AppTypes.error('Failed to Update Policy'));
      yield put(Types.saveTimeConstraintFailed());
      return;
    }
    const policy_modifiers = yield call(
      Api.modifiers.get,
      store.account.selected
    );

    yield put(Types.getModifierSuccess(policy_modifiers['modifiers']));
    yield put(Types.saveTimeConstraintSuccess());
    yield put(AppTypes.success('shared.advancedConfigurationUpdated'));
  } catch (error) {
    console.log('Error: ', error);
    yield put(AppTypes.error('Failed to Update Policy'));
  }
}

export function* advancedFilteringReducerFlow() {
  yield takeEvery(Types.AF_ADD_SAFESEARCH, addSafesearch);
  yield takeEvery(Types.AF_ADD_URL_LOGGING, addUrlLogging);
  yield takeEvery(Types.AF_ADD_RULE, addRule);
  yield takeEvery(Types.AF_GET_RULESETS, getRulesets);
  yield takeEvery(Types.AF_EDIT_RULE, editRule);
  yield takeEvery(Types.AF_EDIT_EVERYONE_RULE, editEveryoneRule);
  yield takeEvery(Types.AF_DELETE_RULE, deleteRule);
  yield takeEvery(Types.AF_DELETE_RULESET_RULE, deleteRulesetRule);
  yield takeEvery(Types.SUBMIT_RULES_REORDER, reorderRules);
  yield takeEvery(Types.SUBMIT_RULE_ORDER, reorderRules);
  yield takeEvery(Types.SUBMIT_RULESETS_REORDER, reorderRulesets);
  yield takeEvery(Types.SUBMIT_RULESET_ORDER, reorderRulesets);
  yield takeEvery(Types.AF_APPLY_SEARCH_FILTER, getFilterRulesets);
  yield takeEvery(Types.AF_SAVE_TIME_CONSTRAINT, saveTimeConstraint);
  yield takeEvery(Types.AF_FILTER_SEARCH_INPUT, filterUsers);
  yield takeEvery(Types.AF_FILTER_AD_SEARCH_INPUT, filterUsersGroups);
}
